import { useMutation } from "@tanstack/react-query"
import { VariableValues } from "@easy-templates/types"
import { Result } from "@easy-templates/lib"
import { useAppContext } from "components/AppContextProvider"

type Params = {
  issueKey: string
  projectId: string
  templateId: string
  variableValues: VariableValues
}

type Job = {
  jobId: string
}

type OperationResults = {
  [key: string]: {
    issueId: string,
    status: string,
    result: string
  }
}


export type Action = (templateId: string, variableValues: {}) => Promise<Result<OperationResults>>

export const useApplyTemplate = () => {
  const { core } = useAppContext()

  const { mutateAsync, isPending: isLoading } = useMutation<Job
    | OperationResults
    | Result<OperationResults>, Error, Params>({
      mutationFn: async ({ templateId, variableValues, issueKey, projectId }) => {
        const filteredVariableValues = Object.fromEntries(
          Object.entries(variableValues).filter(([_, value]) => value !== null && value !== undefined)
        )

        const result = await core.applyTemplate({ id: templateId, variableValues: filteredVariableValues, issueKey, projectId })

        return result
      },
    })

  return {
    applyTemplate: mutateAsync,
    isProcessing: isLoading,
  }
}

export default useApplyTemplate
