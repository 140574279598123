import React from 'react'
import Loading from 'components/Loading'
import Survey from 'components/survey'
import Help from 'components/Help'
// import SupportUkraine from 'components/SupportUkraine'

import useStyles from './useStyles'

interface Props {
  children: React.ReactNode
  actions?: React.ReactNode
  isLoading?: boolean
}

const Layout = ({ children, actions, isLoading }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {isLoading ? <Loading size='xlarge' /> : children}
      <div className={classes.footer}>
        <div className={classes.links}>
          {/* <SupportUkraine /> */}
          <Help />
        </div>

        <div className={classes.buttons}>{actions}</div>
      </div>
      <div className={classes.basement}>
        <Survey />
      </div>
    </div>
  )
}
export default Layout
